.sand {
  background-color: var(--BrandSand);
}
.salamander {
  background-color: var(--BrandSalamander);
}
.forest {
  background-color: var(--BrandForest);
}
.duckegg {
  background-color: var(--BrandDuckegg);
}
.buttercup {
  background-color: var(--BrandButtercup);
}
.moss {
  background-color: var(--BrandMoss);
}
.white {
  background-color: var(--White);
}
