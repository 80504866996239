@import '~ethos-design-system/src/components/Media/Media.scss';

.storyWrapper {
  @include for-phone-only {
    max-width: 480px;
    margin: 0 auto;
    padding-top: var(--Space-48) !important;
    padding-bottom: var(--Space-48) !important;
  }

  @include for-tablet-only {
    max-width: 560px;
    margin: 0 auto;
  }

  @include for-laptop-only {
    padding: 0 var(--Space-64) !important;
  }

  @include for-tablet-and-up {
    padding-top: var(--Space-64) !important;
    padding-bottom: var(--Space-64) !important;
  }

  @include for-desktop-only {
    padding-top: var(--Space-80) !important;
    padding-bottom: var(--Space-80) !important;
  }
}

.headingWrapper {
  @include for-laptop-only {
    margin-right: 64px;
    width: calc(50% - 128px);
  }

  @include for-desktop-only {
    margin-right: 80px;
    width: calc(50% - 160px);
  }

  // Align the Title with adjacent column
  margin-top: -7px;
}

.flexRow {
  @include for-laptop-and-up {
    display: flex;
  }
}

.greyLine {
  width: 1px;
  border-left: 1px #cccccc solid;
  @include for-phone-and-tablet {
    visibility: hidden;
  }
  @include for-laptop-only {
    margin: 0 64px;
  }
  @include for-desktop-only {
    margin: 0 80px;
  }
}

.heading,
.subHeading,
.columnOne,
.columnTwo {
  max-width: 560px;
}

.singleColumn {
  @include for-laptop-only {
    margin-left: 64px;
    width: calc(50% - 128px);
  }
  @include for-desktop-only {
    margin-left: 80px;
    width: calc(50% - 160px);
  }
}

.doubleColumn {
  @include for-laptop-and-up {
    display: flex;
  }
  @include for-laptop-only {
    margin-top: 56px;
  }
  @include for-desktop-only {
    margin-top: 72px;
  }
}

/* Markdown Component Styles */

.columnTwo {
  // Handle spacing when columns combine into single column
  @include for-phone-and-tablet {
    // margin-top: 40px;
    :nth-child(1) {
      p,
      ul,
      ol,
      .subHeading {
        margin-top: 24px;
      }
      &.heading {
        margin-top: 40px;
      }
    }
  }
}

.singleColumn,
.doubleColumn {
  @include for-phone-and-tablet {
    margin-top: 40px;
  }

  .heading,
  .subHeading {
    display: block;
  }

  p,
  ul,
  ol {
    margin: 0;
    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  ul,
  ol {
    padding: 0;
    margin: 0 0 0 30px;
  }

  .subHeading:not(:first-child) {
    margin-top: 24px;
  }

  .heading {
    &:not(:first-child) {
      margin-top: 40px;
    }
    + .subHeading {
      margin-top: 16px;
    }
    + p,
    + ul,
    + ol {
      margin-top: 24px;
    }
  }

  .heading + p {
    margin-top: 16px;
  }

  .subHeading + p {
    margin-top: 8px;
  }
}
